import React from 'react'
import styled from 'styled-components'
import { FaJava } from 'react-icons/fa'

import { scrollToSection } from '../utils/scrollToSection';

const Title = styled.h1`
    text-shadow: 1.5px 1.5px var(--primary);
`;

export default function Hero() {
  return (
    <div id="home" className='mt-44 overflow-x-hidden'>
        <div className="text-center">
            <Title className='text-6xl font-black'>Hi, I'm Lukas!</Title>

            <div className="flex items-center justify-center mt-2">
                <h4 className='text-2xl text-primary font-semibold'>Java Developer</h4>
                <FaJava className='ml-2 text-primary text-3xl' />
            </div>
                
            <div className="flex items-center justify-center space-x-3 mt-8">
                <a href="#portfolio"
                    onClick={(e) => {
                        e.preventDefault();
                        scrollToSection('#portfolio');
                    }}
                    className='font-semibold bg-primary text-background px-6 py-2 rounded-lg'
                >
                    See my work
                </a>

                <a
                    href="#contact"
                    onClick={(e) => {
                        e.preventDefault();
                        scrollToSection('#contact');
                    }}
                    className='font-semibold text-primary border-2 border-primary px-6 py-2 rounded-lg hover:bg-primary hover:text-background transition'
                >
                    Contact me
                </a>
            </div>
        </div>
    </div>
  )
}
