import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from './components/Navbar'

import Hero from './sections/Hero'
import About from './sections/About'
import Portfolio from './sections/Portfolio'
import Contact from './sections/Contact'

import Footer from './components/Footer'

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <>
      <Navbar />

      <Hero />
      <About />
      <Portfolio />
      <Contact />

      <Footer />
    </>
  );
}

export default App;
