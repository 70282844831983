import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { FaDiscord, FaGithub } from 'react-icons/fa'
import { TfiMenu, TfiClose } from 'react-icons/tfi'

import { scrollToSection } from '../utils/scrollToSection';

const Nav = styled.nav`
    background: #090014
`;

export default function Navbar() {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 768);

  const navLinks = [
    {
        name: 'About',
        location: '#about'
    },
    {
        name: 'Portfolio',
        location: '#portfolio'
    },
    {
        name: 'Contact Me',
        location: '#contact'
    }
  ]

  const navSocials = [
    {
        icon: <FaDiscord />,
        location: 'https://discord.com/invite/mCMTT3Pg9B'
    },
    {
        icon: <FaGithub />,
        location: 'https://github.com/imlukas'
    }
  ]

  const handleOpen = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
      setIsOpen(false);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, isOpen])

  return (
    <Nav className='py-6'>
        <div className="container">
            <div className="flex justify-between items-center">
                <h4 className='font-black text-2xl'>
                    lukas
                </h4>

                <ul className='space-x-8 font-semibold hidden md:flex'>
                    {navLinks.map((item, idx) => (
                        <li key={idx} className='hover:text-primary transition'>
                            <a
                                href={item.location}
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection(item.location);
                                }}
                            >
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ul>

                <div className='space-x-3 text-2xl flex'>
                    {navSocials.map((item, idx) => (
                        <a key={idx} href={item.location}>
                            {item.icon}
                        </a>
                    ))}
                </div>
                
                {isMobile ? (
                    isOpen ? (
                        <TfiClose onClick={handleOpen} className='cursor-pointer' />
                    ) : (
                        <TfiMenu onClick={handleOpen} className='cursor-pointer' />
                    )
                ) : null}
            </div>

            <ul className={`font-semibold ${isOpen ? '' : 'hidden'} mt-6`}>
                {navLinks.map((item, idx) => (
                    <li key={idx} className='hover:text-primary transition mt-2'>
                        <a href={item.location}>
                            {item.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </Nav>
  )
}
