import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { FaGithub } from 'react-icons/fa';

import nologo from '../assets/no-logo.jpg';
import withdrawerIcon from '../assets/withdrawer-icon.png';
import withdrawerVid from '../assets/withdrawer-vid.mp4';
import veruxIcon from '../assets/verux-icon.jpg';
import prisonCore from '../assets/prison-core-temp.png';
import axolotlIcon from '../assets/axolotl.png';
const portfolioItems = [
  {
    name: 'Withdrawer',
    description: 'Withdrawer is a way for players to withdraw their money, exp, hp or others onto physical items, so they can redeem them, trade them, sell them or store them as they wish.',
    thumbnail: withdrawerIcon,
    media: [
      {
        type: 'image',
        source: withdrawerIcon
      },
      {
        type: 'video',
        source: withdrawerVid
      }
    ],
    github: 'https://github.com/imlukas/withdrawer'
  },
  {
    name: 'Verux',
    description: 'Discord bot that aims to make server management easier, whilst also providing a lot of fun commands. ' +
      'It is currently in development, you can join the support server and talk about any new stuff you would like to see.',

    thumbnail: veruxIcon,
    media: [
      {
        type: 'image',
        source: veruxIcon
      }
    ],
    github: 'https://github.com/imlukas/verux'
  },
  {
    name: 'Prison Core',
    description: 'Scalable prison core for Minecraft servers. This project is currently in development and in very early stages.',
    thumbnail: prisonCore,
    media: [
      {
        type: 'image',
        source: prisonCore
      }
    ],
    github: 'https://github.com/imlukas/PrisonCore'
  },
  {
    name: 'AxolotlSMP',
    description: 'I am one of the core developers of AxolotlSMP. I made many different plugins, some of them to integrate with other plugins.',
    media:
    [
      {
        type: 'image',
        source: axolotlIcon
      }
    ],
    thumbnail: axolotlIcon,
    github: ''
  },
  {
    name: 'Hoarder',
    description: 'The hoarder is a fun event which consists in players gathering the most items in a given time, the player with the most items wins. ' + 
    'The hoarder as fully integrated MySQL and a leaderboard system. All with pre-made GUIs and easy to use commands. Plug-in and play!',
    media:
    [
      {
        type: 'image',
        source: nologo
      }
    ],
    thumbnail: nologo,
    github: 'https://github.com/imlukas/Hoarder'
  },
  ,
  {
    name: 'CustomItemMaker',
    description: 'CustomItemMaker is a library/API that allows developers to easily create custom items, with custom implementations. ' + 
    'This is very useful to create classic hub items and custom items for minigames.',
    media:
    [
      {
        type: 'image',
        source: nologo
      }
    ],
    thumbnail: nologo,
    github: 'https://github.com/imlukas/CustomItemMaker'
  }
];

export default function Portfolio() {
  const [showModal, setShowModal] = useState(false);
  const [portfolioItem, setPortfolioItem] = useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpenItem = (idx) => {
    setPortfolioItem(portfolioItems[idx]);
    setCurrentImageIndex(0);
    setShowModal(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % portfolioItem.media.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + portfolioItem.media.length) % portfolioItem.media.length);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div id="portfolio" className="mt-44 overflow-x-hidden">
      <h2 className="text-4xl text-center font-bold">
        <span className="border-b-2 border-primary">Portfolio</span>
      </h2>

      <div className="container mt-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-12">
          {portfolioItems.map((item, idx) => (
            <div key={idx} className="relative overflow-hidden" data-aos="zoom-in">
              <img
                src={item.thumbnail}
                alt=""
                className="m-auto transition-transform transform hover:scale-110 cursor-pointer"
                onClick={() => handleOpenItem(idx)}
              />
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-4 select-none" onClick={() => setShowModal(false)}>
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-background outline-none focus:outline-none" onClick={handleModalClick} data-aos='zoom-in'>
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {portfolioItem.name}
                  </h3>
                </div>

                <div className="relative px-6 flex-auto">
                  <div className="flex justify-center relative">
                    <FiChevronLeft
                      className="text-white text-4xl cursor-pointer absolute left-4 top-1/2 transform -translate-y-1/2 opacity-60"
                      onClick={handlePrevImage}
                    />
                    {portfolioItem.media[currentImageIndex].type === 'image' ? (
                      <img
                        src={portfolioItem.media[currentImageIndex].source}
                        alt=""
                        className="max-h-[350px] max-w-full"
                      />
                    ) : (
                      <video
                        controls
                        src={portfolioItem.media[currentImageIndex].source}
                        className="max-h-[350px] max-w-full"
                      />
                    )}
                    <FiChevronRight
                      className="text-white text-4xl cursor-pointer absolute right-4 top-1/2 transform -translate-y-1/2 opacity-60"
                      onClick={handleNextImage}
                    />
                  </div>
                </div>

                <div className="relative px-6 flex-auto">
                  <p className="my-4 text-purplishWhite text-md leading-relaxed">
                    {portfolioItem.description}
                  </p>
                </div>

                <div className={`flex ${portfolioItem.github !== '' ? 'justify-between' : 'justify-end'} p-6`}>
                  {portfolioItem.github !== '' ? (
                    <a href={portfolioItem.github} rel='noreferrer' target='_blank' className='text-3xl'>
                      <FaGithub />
                    </a>
                  ) : null}

                  <button
                    className="text-primary font-bold uppercase text-sm mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
}