import React from 'react'

const skills = [
    {
        name: 'Spigot API & Usage of Databases',
    },
    {
        name: 'Quality Assurance Testing',

    },
    {
        name: 'NMS and Packets & Proxy development',

    }
]

export default function About() {
  return (
    <div id="about" className="mt-44 overflow-x-hidden">
        <h2 className='text-4xl text-center font-bold'>
            <span className="border-b-2 border-primary">About</span>
        </h2>

        <div className="container mt-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div data-aos='fade-right'>
                    <h4 className="text-3xl font-semibold">Lukas Pinheiro</h4>
                    <p className='text-purplishWhite text-s mt-3 font-regular'>
                    👋 I am a Java Engineer / Freelancer with 2+ 𝐲𝐞𝐚𝐫𝐬 𝐨𝐟 𝐞𝐱𝐩𝐞𝐫𝐢𝐞𝐧𝐜𝐞, 
                    I build customized software solutions for clients, leveraging my technical expertise in Java, as well as my strong communication and problem-solving skills. 
                    I collaborate with clients to understand their business needs and develop solutions that align with their goals.
                    <br></br>
                    Feel free to reach out with so we can discuss your project!
                    </p>
                </div>
                <div data-aos='fade-left'>
                    {skills.map((item, idx) => (
                        <div key={idx} className='bg-primary px-4 py-3 rounded-lg mb-3'>
                            <div className="flex justify-between">
                                <p className='font-medium'>{item.name}</p>
                            
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}
