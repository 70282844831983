import React, { useState } from 'react'
import axios from 'axios'

import { BsEnvelope, BsTelegram, BsDiscord } from 'react-icons/bs';
import { FaPaperPlane } from 'react-icons/fa';

const contactItems = [
    {
        icon: <BsEnvelope />,
        
        name: 'Email',
        text: 'development@imlukas.dev'
    },
    {
        icon: <BsTelegram />,
        
        name: 'Telegram',
        text: '@lukasdev'
    },
    {
        icon: <BsDiscord />,

        name: 'Discord',
        text: 'lukas.'
    }
]

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name.trim().length === 0 || formData.email.trim().length === 0 || formData.subject.trim().length === 0 || formData.message.trim().length === 0) {
        setMessage('Please, fill all fields.');
        return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)) {
        setMessage('Provide a valid email.');
        return;
    }

    const formDataObject = new FormData();
    formDataObject.append("name", formData.name);
    formDataObject.append("email", formData.email);
    formDataObject.append("subject", formData.subject);
    formDataObject.append("message", formData.message);

    axios.post("/contact.php", formDataObject, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        if (response.status === 201) {
            setMessage ('Your form has been sent successfully. You\'ll be contacted soon!');
            setSuccess (true);
        }
    })
    .catch(error => {
        setMessage ('Oops, something went wrong. Please, try again.');
    });
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div id="contact" className="mt-44 overflow-x-hidden">
        <h2 className='text-4xl text-center font-bold'>
            <span className="border-b-2 border-primary">Contact Me</span>
        </h2>

        <div className="container mt-12">
            <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
                <div className="md:col-span-2" data-aos='fade-right'>
                    <div className="bg-sectionBackground p-4 rounded-xl">
                        {contactItems.map((item, idx) => (
                            <div className={`flex items-center space-x-4 ${idx > 0 ? 'mt-4' : ''}`} key={idx}>
                                <div className='bg-primary rounded-full p-4 text-2xl'>
                                    {item.icon}
                                </div>
                                <div>
                                    <h6 className='text-primary font-medium text-lg'>{item.name}</h6>
                                    <p className='text-purplishWhite text-sm'>{item.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="md:col-span-3" data-aos='fade-left'>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <input type="text" placeholder="Name" name="name" className="w-full px-4 py-2 rounded-full border border-sectionBackground bg-sectionBackground focus:border-primary focus:outline-none transition" onChange={handleInputChange} required />
                            </div>
                            <div>
                                <input type="email" placeholder="Email" name="email" className="w-full px-4 py-2 rounded-full border border-sectionBackground bg-sectionBackground focus:border-primary focus:outline-none transition" onChange={handleInputChange} required />
                            </div>
                        </div>
                        <div className="mb-4">
                            <input type="text" placeholder="Subject" name="subject" className="w-full px-4 py-2 rounded-full border border-sectionBackground bg-sectionBackground focus:border-primary focus:outline-none transition" onChange={handleInputChange} required />
                        </div>
                        <div className="mb-4">
                            <textarea name="message" rows="4" placeholder="Message" className="w-full px-4 py-2 rounded-xl border border-sectionBackground bg-sectionBackground focus:border-primary focus:outline-none transition" onChange={handleInputChange} required></textarea>
                        </div>
                        <div>
                        <button className={`flex items-center justify-center py-3 px-6 rounded-lg w-full font-semibold bg-primary 
                        hover:bg-purple-600 transition ${success ? 'hidden' : ''}`} type='submit'><span className='mr-2'>Send</span> <FaPaperPlane /></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
